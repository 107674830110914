nav a {
  display: inline-block;
  padding: 10px 50px;
  text-decoration: none;
  text-transform: uppercase;
}

nav a.active {
  font-weight: bold;
}

nav a:hover{
  color: white;
  text-decoration: none;
}

nav {
  background-color: rgb(49,30,134);
}