.Homepage-greeting {
  text-shadow: none;
  background-color: rgb(49,30,134);
  max-width: fit-content;
  margin: 30px auto;
  padding: 15px 15px;
  border-radius: 18px;
  -webkit-box-shadow: 10px 10px 7px -4px rgba(42, 29, 101, 0.54);
  -moz-box-shadow: 10px 10px 7px -4px rgba(42, 29, 101, 0.54);
  box-shadow: 10px 10px 7px -4px rgba(42, 29, 101, 0.54);
}

.Homepage {
  padding: 8em;
  color: rgb(244, 225, 255);
  text-shadow: 3px 2px 0px rgb(49,30,134);
  text-align: center;
  letter-spacing: 1px;
}


.Homepage-btn {
  margin: 10px 10px;
  color: rgb(244, 225, 255);
  background-color: rgb(49,30,134);
}

.Homepage-btn:hover {
  color:rgb(192, 125, 255);

}

