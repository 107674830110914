.RegisterForm-error {
  color: darkred;
}

.RegisterForm-container {
  border: 1px solid black;
}

.RegisterForm {
  color: rgb(49,30,134);
}

.RegisterForm-btn {
  margin: 10px 10px;
  color: rgb(244, 225, 255);
  background-color: rgb(49,30,134);
}

.RegisterForm-btn:hover {
  color:rgb(192, 125, 255);
}