.CompanyCard {
  text-align:left;
  margin-bottom: 20px;
  background-color: rgb(255, 255, 255, 0.9);
  border-radius: 5px;
}

.CompanyCard a {
  color: black;
  text-decoration: none;
}

.CompanyCard a:hover {
  color: blue;
}

.CompanyCard img {
  max-width: 4rem;
  position: absolute;
  top: 10px;
  right: 20px;
}
