.EditProfileForm-error {
  color: darkred;
}

.EditProfileForm-success {
  color: rgb(10, 126, 10);
}

EditProfileForm label {
  text-align: left;
}

.EditProfileForm {
  color: rgb(49,30,134);
}

